import logo from "../assets/img/sunshine_logo_white_text_small.png";
import {Link} from "react-router-dom";
import { FaLinkedin } from 'react-icons/fa'
import {MdPhoneInTalk, MdEmail, MdLocationOn} from 'react-icons/md'

export default function Footer(){
  return (
    <footer className="bg-brand-gray-600 px-8 md:px-16 pt-16 pb-8 md:pb-16">
      <div className="flex w-full justify-between flex-wrap 2xl:flex-nowrap">
        <Link to="/" className="flex-shrink-0 flex items-center mr-auto 2xl:mr-0">
          <span className="sr-only">Logo</span>
          <img
            className="block h-24 w-auto"
            src={logo}
            alt=""
          />
        </Link>
        <div className="flex items-start mt-8 xl:mx-4 2xl:max-w-6xl w-full justify-between flex-col xl:flex-row">
          <div className="flex items-center text-white mr-8 mb-10 2xl:mb-0">
            <MdEmail className="text-4xl" />
            <span className="text-base sm:text-lg ml-4">info@sunshineEnv.com</span>
          </div>
          <div className="flex items-center text-white mr-8 mb-10 2xl:mb-0">
            <MdPhoneInTalk className="text-4xl" />
            <span className="text-base sm:text-lg ml-4">631-320-2350</span>
          </div>
          <div className="flex items-center text-white mr-8 mb-10 2xl:mb-0">
            <MdLocationOn className="text-4xl self-start" />
            <span className="text-base sm:text-lg ml-4">
          Sunshine Environmental Solutions LLC<br/>
          80E East Jefryn Blvd<br/>
          Deer Park, NY 11729
            </span>
          </div>
          <div className="flex flex-col items-start text-white mx-2">
            <a href="https://www.linkedin.com/company/sunshine-environmental-solutions" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="text-4xl self-start m-1" />
            </a>
          </div>
        </div>
        <a className="p-3" href="https://www.bbb.org/us/ny/deer-park/profile/environmental-testing/sunshine-environmental-solutions-llc-0121-87150748/#sealclick"
           target="_blank" rel="noopener noreferrer">
          <img src="https://seal-newyork.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-87150748.png" style={{border: 0}} alt="Sunshine Environmental Solutions LLC BBB Business Review" />
        </a>
      </div>
      <div className="text-xs md:text-base lg:text-xl text-white mt-8">
        © Copyright 2021 - Sunshine Environmental Solutions. All Rights Reserved.
      </div>
    </footer>
  )
}
