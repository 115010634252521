export default function Header({children, title, className}){
  return (
      <header id="section-intro" className={`relative overflow-hidden ${className ?? ''}`}>
        <div className="flex flex-col items-center justify-center py-28 lg:py-56 max-w-6xl mx-auto">
          <h1 className="z-10 font-bold text-2xl lg:text-5xl text-center text-white mb-10 md:mb-20 leading-snug px-3">{title}</h1>
          {children}
        </div>
        <div className="z-100 absolute -bottom-px h-20 left-0 right-0">
          <div
            className="w-full h-full absolute pointer-events-none overflow-hidden"
          >
            <svg
              className="h-full w-full"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 100 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="0,0, 50,95 100,0 100,100 0,100"
              />
            </svg>
          </div>
        </div>
      </header>
  )
}
