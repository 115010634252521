import { FaBiohazard, FaCheckSquare, FaSchool, FaExclamationTriangle, FaMicroscope } from 'react-icons/fa'
import Header from "../components/Header";
import ContactUsAction from "../components/ContactUsAction";
import AnchorLink from 'react-anchor-link-smooth-scroll'
const servicesContent = [
  {
    link: 'Lead Testing',
    hash: 'lead',
    title: 'Lead Testing and Abatement',
    icon: <FaMicroscope className="text-white" style={{fontSize: 90}} />,
    img: '',
    tagline: 'Mitigating lead hazards in the environment',
    bgClass: 'bg-cover bg-lead-testing',
    list: [
     'Lead may be present in all parts of your environment: the air, the water, the soil, in your water pipes, or in the paint on your wall. This hazard can make you, your family, or your employees sick.',
     'We provide testing and remediation of toxic lead in all parts of your environment. We are a licensed lead abatement company that can remove the lead from your environment safely.',
     'Our specialists are expert lead consultants and technicians. They conduct encapsulations of lead paint, remove and dispose of lead-infested soil, and create effective barriers to prevent exposure and keep you safe. They also conduct risk assessments and lead inspections.',
     'Additionally, we are licensed lead abatement supervisors and can supervise lead-related projects of any size.'
    ]
  },
  {
    link: 'Indoor air quality',
    hash: 'air',
    title: 'Indoor and Outdoor Air Quality Testing',
    icon: <FaSchool className="text-white" style={{fontSize: 90}} />,
    img: '',
    tagline: 'Testing for contaminants in the air',
    bgClass: 'bg-air-quality bg-cover bg-center',
    list: [
      'We test for contaminants in the air, such as allergens and volatile organic compounds (VOCs), that make people sick. We conduct testing in commercial and residential buildings.',
      'We test in manufacturing settings, where air often contains pollutants, usually, the byproducts of burning gasoline and oil, the aftereffects of a spill or explosion, or the byproducts commodities such as cosmetics and aerosols. We also work in residential settings, making sure the air in your home is safe to breathe.',
      'We also test outdoor air, analyzing airborne dust and identifying pollutants such as freon. Sometimes pollutants from the air become saturated in the soil, which we can also test for.',
      'Our company is a member of the Indoor Air Quality Association.',
    ]
  },
  {
    link: 'Mold Testing',
    hash: 'mold',
    title: 'Toxic Mold Testing and Remediation',
    icon: <FaExclamationTriangle className="text-white" style={{fontSize: 90}} />,
    img: '',
    tagline: 'Removing toxic mold from residential and commercial buildings',
    bgClass: 'bg-toxic-mold bg-cover bg-center',
    list: [
      'We are certified mold microscopists who are experts in molds of all types. We use cutting-edge science to analyze and eliminate the mold risk in your environment.',
      'Toxic mold destroys whatever it comes in contact with, and makes people sick. Mold can exist in all parts of your environment, on surfaces, or in the air.',
      'Mold depends on moisture to keep growing. We locate and identify mold in the environment, test it, and then either remediate or remove it, depending on the extent of the infestation.'
    ]
  },
  {
    link: 'Asbestos survey',
    hash: 'consulting',
    title: 'Environmental Consulting',
    icon: <FaBiohazard className="text-white" style={{fontSize: 90}} />,
    img: '',
    tagline: 'Designing asbestos removal projects and performing general project management',
    bgClass: 'bg-consulting bg-cover bg-center',
    list: [
      'Asbestos is a very prevalent hazard in the environment, which is used in insulation. Exposure can cause cancer, so it is vital to remove it safely and effectively. We conduct expert-level asbestos surveys and create detailed asbestos site project designs for contractors.',
      'We are experts in construction project management of all types. Our expertise spans from metalworks repair, to painting, to construction site dust monitoring. With our certificate in project management from Cornell University, we can handle every aspect of the work to make sure your project goes smoothly.',
      'Whatever your goal or your needs, we can find the right contractors with the right skills to get the job done. We will supervise and manage your project from beginning to end.'
    ]
  },
  {
    link: 'Industrial site assessments',
    hash: 'assessments',
    title: 'Industrial site assessments',
    icon: <FaCheckSquare className="text-white" style={{fontSize: 90}} />,
    img: '',
    tagline: 'Conducting Phase 1 and Phase 2 assessment',
    bgClass: 'bg-site-assessments bg-cover bg-center',
    list: [
      'We are licensed and certified to conduct both Phase 1 (investigation) and Phase 2 (soil testing and remediation) for industrial site assessment, both of which are necessary components of acquiring financing for residential and commercial construction projects.',
      'In Phase 1, we compile an environmental history of the building site. We conduct a thorough investigation of your site, using both the documented history of the land and physical discovery techniques to identify pollution and potential environmental hazards.',
      'If Phase 1 is positive for environmental hazards, we conduct a Phase 2 assessment. In this phase, we use machines to test the soil and identify any dangerous objects below the ground.',
      'We are environmental site assessment professionals who can identify the level of risk in any project.',
    ]
  }
]

const LeftTitle = ({title, icon, bgClass}) => (
  <div className="flex w-full pb-14">
    <div className="relative bg-brand-yellow w-8/12">
      <div className="flex items-center justify-center max-w-screen-lg py-3 mr-0 ml-auto">
        {icon}
        <h2 className="ml-20 text-brand-gray-600 text-3xl font-bold uppercase">{title}</h2>
      </div>
    </div>
    <div className={`flex w-4/12 relative ${bgClass}`}>
      <div className="absolute w-12 -bottom-px -top-px -left-px overflow-hidden">
        <div
          className="w-full h-full absolute pointer-events-none overflow-hidden"
        >
          <svg
            className="h-full w-full"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 100 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-brand-yellow fill-current"
              points="0,0, 95,50 0,100"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
)

const RightTitle = ({title, icon, bgClass}) => (
  <div className="flex w-full pb-14">
    <div className={`flex w-4/12 relative ${bgClass}`}>
      <div className="absolute w-12 -bottom-px -top-px -right-px transform rotate-180 overflow-hidden">
        <div
          className="w-full h-full absolute pointer-events-none overflow-hidden"
        >
          <svg
            className="h-full w-full"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 100 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-brand-yellow fill-current"
              points="0,0, 95,50 0,100"
            />
          </svg>
        </div>
      </div>
    </div>
    <div className="relative w-8/12 bg-brand-yellow">
      <div className="flex items-center justify-center max-w-screen-lg py-3 mr-auto ml-0">
        <h2 className="mr-20 text-brand-gray-600 text-3xl font-bold uppercase">{title}</h2>
        {icon}
      </div>
    </div>
  </div>
)

export default function Services(){
  return (
    <>
      <Header title="WHAT WE OFFER" className="bg-hardhats bg-cover bg-top">
        <div className="w-full h-full absolute top-0 left-0 bg-brand-gray opacity-50" />
      </Header>
      <section className="flex justify-center py-44">
        <div className="flex w-full justify-around items-center max-w-screen-3xl">
          {servicesContent.map(({link, hash}) => (
            <AnchorLink key={hash} href={`#${hash}`} className="text-lg md:text-2xl uppercase font-bold text-brand-gray-dark border-b-2 border-brand-yellow pb-1 border-opacity-0 hover:border-opacity-100 focus:border-opacity-100 focus:text-brand-yellow">
              {link}
            </AnchorLink>
          ))}
        </div>
      </section>
      {servicesContent.map(({title, hash, icon, tagline, list, bgClass}, index) => (
        <section id={hash} key={hash} className="">
          {index % 2 === 0 ? <LeftTitle {...({title, icon, bgClass}) } /> : <RightTitle {...({title, icon, bgClass}) } />}
          <div className="max-w-screen-lg mx-auto">
            <h4 className="text-2xl text-brand-gray-600 italic font-bold mb-14">{tagline}</h4>
            <ul className="list-disc list-outside ml-10 mb-32">
              {list.map((body) => (<li className="text-2xl text-brand-gray-600 mb-4">{body}</li>))}
            </ul>
          </div>
        </section>
      ))}
      <ContactUsAction />
    </>

  )
}
