import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import mapStyles from './mapStyles'

const MapContainer = ({ google }) => {
  return (
      <Map google={google}
           zoom={13}
           id={1}
           styles={mapStyles}
           initialCenter={{ lat: 40.764551, lng: -73.292173}}
           disableDefaultUI={true}>
        <Marker position={{ lat: 40.757474, lng: -73.29805}}>
          <InfoWindow visible={true}>
            <div>
              <p>Click on the map or drag the marker to select location where the incident occurred</p>
            </div>
          </InfoWindow>
        </Marker>
      </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAgyVNLGd0G4D6AAGaiw3z3_XJVKBRd6W4'
})(MapContainer)
