import { useState } from 'react'
import Header from "../components/Header";
import {MdEmail, MdLocationOn, MdPhoneInTalk} from "react-icons/md";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import Map from '../components/Map'
const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Input = ({ label, name, onChange, disabled}) => (
  <div className="relative border-b-3 border-white focus-within:border-b-6 font-semibold focus-within:font-bold mb-16 flex">
    <label className="z-10 absolute inline-block text-brand-gray-600 text-2xl w-36">{label}</label>
    <input name={name} onChange={onChange} disabled={disabled} className="z-20 pl-36 pt-1 pb-3 bg-transparent focus:outline-none flex-grow text-brand-gray" />
  </div>
)

export default function Contact(){
  const [formValues, setFormValues] = useState(
    {
      first_name: '',
      last_name: '',
      email: '',
      message: ''
    })

  const [status, setStatus] = useState('')

  const handleSubmit =  (e) => {
    if(status === ''){
      setStatus('loading')
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formValues })
      })
        .then(() => {
          setTimeout(() => setStatus('submitted'), 2000)
          setTimeout(() => setStatus(''), 10000)
        })
        .catch(error => alert(error));
    }
    e.preventDefault();
  }

  const handleChange = e => setFormValues((prev) => ({...prev, [e.target.name]: e.target.value }));

  return (
    <>
      <Header title="CONTACT US" className="bg-contact-us bg-cover bg-center">
        <div className="w-full h-full absolute top-0 left-0 bg-brand-gray opacity-50" />
      </Header>
      <section className="max-w-screen-lg w-full mx-auto">
        <div className="flex flex-col items-center justify-center pt-40 pb-20 max-w-6xl mx-auto">
          <h2 className="text-brand-gray-600 text-4xl font-bold">We would love to work with you</h2>
          <span className="text-brand-gray-600 text-4xl">Reach out to us to schedule a consultation</span>
        </div>
        <div className="flex items-start mt-8 mb-52 w-full justify-between">
          <div className="flex flex-col items-center text-brand-gray-600">
            <MdEmail className="text-8xl text-brand-yellow mb-12" />
            <h2 className=" text-4xl font-bold mb-5">Email Us</h2>
            <span className="text-xl ml-4">info@sunshineEnv.com</span>
          </div>
          <div className="flex flex-col items-center text-brand-gray-600 ">
            <MdLocationOn className="text-8xl text-brand-yellow mb-12" />
            <h2 className="text-4xl font-bold mb-5">Find Us</h2>
            <span className="text-xl">
          80E East Jefryn Blvd<br/>
          Deer Park, NY 11729
            </span>
          </div>
          <div className="flex flex-col items-center text-brand-gray-600 ">
            <MdPhoneInTalk className="text-8xl text-brand-yellow mb-12" />
            <h2 className="text-4xl font-bold mb-5">Call Us</h2>
            <span className="text-xl ml-4">631-320-2350</span>
          </div>

        </div>
      </section>
      <section className="w-full mx-auto flex">
        <div className="w-1/2 bg-brand-gray-50 relative">
          <Map />
        </div>
        <div className="w-1/2 bg-brand-yellow p-28 flex flex-col items-center">
            <div className={`flex flex-col p-60 my-2.5 items-center ${status !== 'submitted' ? 'hidden' : ''}`}>
              <h2 className="text-4xl font-bold uppercase text-brand-gray-600">Thank You</h2>
              <span className="mb-5 text-white mb-1 text-2xl">We'll be in touch!</span>
            </div>
            <div className={`h-full w-full flex flex-col items-center ${status === 'submitted' ? 'hidden' : ''}`}>
              <h2 className="text-4xl font-bold mb-5 uppercase text-brand-gray-600 mb-16">Get in Touch</h2>
              <form className="px-7 w-full" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact"/>
                <Input label="First Name" type="text" name="first_name" disabled={!!status} onChange={handleChange}/>
                <Input label="Last Name" type="text" name="last_name" disabled={!!status} onChange={handleChange}/>
                <Input label="Email" type="email" name="email" disabled={!!status} onChange={handleChange}/>
                <Input label="Message" type="text" name="message" disabled={!!status} onChange={handleChange}/>
                <button className="btn btn-white btn-block text-center w-44 mx-auto">
                  <span className={`${status === 'loading' ? 'hidden' : ''}`}>Submit</span>
                  <span className={`${status === 'loading' ? '' : 'hidden'} flex justify-center text-2xl my-0.5`}>
                    <AiOutlineLoading3Quarters className="animate-spin" />
                  </span>
                </button>
              </form>
            </div>
        </div>
      </section>
    </>

  )
}
