import {Link} from 'react-router-dom'
export default function ContactUsAction(){
  return (
    <section id="section-together" className="flex flex-col-reverse md:flex-row w-full overflow-hidden">
        <div className="w-full md:w-6/12 lg:w-5/12 px-5 py-10 md:p-20 bg-brand-yellow">
          <div className="flex flex-col items-center md:items-start h-full justify-around max-w-xl ml-auto mr-auto md:mr-0">
            <h2 className="text-brand-gray-600 text-4xl font-bold uppercase text-center md:text-left">Let's Work Together</h2>
            <span className="text-brand-gray text-xl md:text-2xl text-center md:text-left my-6" >Contact us today to schedule a consultation.</span>
            <Link to="/contact"
              className="hover:bg-brand-gray-50 group flex items-center rounded-full bg-white text-brand-gray text-xl font-bold px-8 py-4">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="relative w-full md:w-6/12 lg:w-7/12 overflow-hidden">
          <div className="bg-building bg-cover bg-center flex flex-col items-center justify-center py-28 md:py-56"/>
          <div className="hidden md:block absolute w-28 -bottom-px -top-px -left-px overflow-hidden">
            <div
              className="w-full h-full absolute pointer-events-none overflow-hidden"
            >
              <svg
                className="h-full w-full transform rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 100 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-brand-yellow fill-current"
                  points="0,0, 95,50 0,100 100,100 100,0"
                />
              </svg>
            </div>
          </div>
        </div>
      </section>
  )
}
