import { useState } from 'react';
import Carousel from "react-simply-carousel"
import { Link } from 'react-router-dom'
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { FaBiohazard, FaCheckSquare, FaSchool, FaExclamationTriangle, FaMicroscope } from 'react-icons/fa'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'
import Header from './../components/Header'
import ContactUsAction from "../components/ContactUsAction";
import videoBg from './../assets/video/splash_page_video_5.mp4'
const AffiliateLogo = ({ className }) => (<div
  className={`${className} flex justify-center items-center h-36 w-36`}/>)

const homeContent = {
  intro: "A team of environmental hygienists and consultants offering high-level environmental solutions for projects large and small.",
  pitch: <><i>Sunshine Environmental Solutions</i> is an industrial hygiene company and environmental pollution consultant serving the private and public sectors. We keep you safe through our diverse array of services, all rooted in cutting-edge science.</>,
  affiliations: [
    { logoClassName: "bg-logo-epa bg-contain bg-no-repeat bg-center" },
    { logoClassName: "bg-logo-mbe bg-contain bg-no-repeat bg-center" },
    { logoClassName: "bg-logo-nyc-mbe bg-contain bg-no-repeat bg-center" },
    { logoClassName: "bg-logo-ny-nj-port bg-contain bg-no-repeat bg-center" },
    { logoClassName: "bg-logo-epa bg-contain bg-no-repeat bg-center" },
    { logoClassName: "bg-logo-mbe bg-contain bg-no-repeat bg-center" },
    { logoClassName: "bg-logo-nyc-mbe bg-contain bg-no-repeat bg-center" },
    { logoClassName: "bg-logo-ny-nj-port bg-contain bg-no-repeat bg-center" },
  ],
  offer: {
    tagLine: "Committed to community wellness and workplace health, we specialize in air, soil, and water testing; mold testing and remediation; lead testing and remediation; asbestos surveying; and more. Our clients are contractors, property managers, real estate companies, and homeowners.",
    services: [
      {
        icon: <FaMicroscope className="text-8xl text-brand-yellow" />,
        title: "Lead Testing",
        description: "Provides testing and remediation of toxic lead"
      },
      {
        icon: <FaSchool className="text-8xl text-brand-yellow" />,
        title: "Indoor air quality",
        description: "Tests for allergens and Volatile Organic Compounds (VOC)"
      },
      {
        icon: <FaExclamationTriangle className="text-8xl text-brand-yellow" />,
        title: "Mold testing",
        description: "Provides testing and remediation of toxic mold"
      },
      {
        icon: <FaBiohazard className="text-8xl text-brand-yellow" />,
        title: "Asbestos survey",
        description: "Provides: Inspection, permit for demolition, and site project designs"
      },
      {
        icon: <FaCheckSquare className="text-8xl text-brand-yellow" />,
        title: "Industrial site assessments",
        description: "Provides comprehensive assessments including Phase (investigation) and Phase II (Soil Testing and Remediation)"
      }
    ]
  },
  know: "We are a family-owned business with the expertise and experience to fix the problem at the source. With our scientific knowledge and extensive network of professionals, we can handle any project from beginning to end. We go the extra mile to anticipate and meet your needs.",
  testimonials: [
    {
      body: "Olu and his team have a top-notch approach to very tight, fast-paced project management and schedules. They always respond quickly with quality workmanship.",
      customer: "S.Edward, Genesis ANCP LLC"
    },
    {
      body: "Sunshine Environmental Solutions is known for their creative thought process and excellent project management skills.",
      customer: "K. Cariuty, Cariuty Consultants LLC"
    }
  ]
}

export default function Home(){
  const [activeSlide, setActiveSlide] = useState(0)
  return (
    <>
      <Header title={homeContent.intro} videoBg={videoBg}>
        <Link to="/about"
          className="hover:bg-brand-yellow group flex items-center rounded-full bg-brand-yellow text-white text-xl font-bold px-11 py-5">
          Learn More
        </Link>
        <div className="absolute top-0 left-0 bg-brand-gray bg-opacity-70 w-full h-full" style={{zIndex: -1}} />
        <video
          className="absolute top-0 left-0 w-full filter blur" style={{zIndex: -2}}
          src={videoBg} type="video/mp4" autoPlay={true} muted={true} loop={true}/>
      </Header>
      <section id="section-pitch">
        <div className="flex flex-col items-center justify-center py-20 md:py-40 max-w-6xl mx-auto">
          <hr className="w-1/2 border-brand-yellow mb-9 border-t-3" />
          <p className="text-2xl lg:text-3xl font-semibold text-center text-brand-gray-600 px-5 md:px-16 lg:px-0">{homeContent.pitch}</p>
          <hr className="w-1/2 border-brand-yellow mt-9 border-t-3" />
        </div>
      </section>
      <section id="section-affiliations">
        <div className="flex flex-col items-center justify-center max-w-6xl mx-auto">
          <h2 className="text-brand-gray-600 text-4xl font-bold uppercase">Affiliations</h2>
          <span className="text-brand-gray text-xl md:text-2xl my-6 text-center px-5 md:px-0" >We are proud to be accredited with:</span>
          <Carousel
            updateOnItemClick
            containerProps={{className: "w-full justify-between"}}
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: <HiOutlineArrowNarrowRight className="text-5xl text-brand-yellow focus:outline-none" />,
              className: "focus:outline-none self-center",
            }}
            backwardBtnProps={{
              children: <HiOutlineArrowNarrowLeft className="text-5xl text-brand-yellow focus:outline-none" />,
              className: "focus:outline-none self-center",
            }}
            responsiveProps={[
              {minWidth: 1024, itemsToShow: 4},
              {minWidth: 768, maxWidth: 1023, itemsToShow: 3},
              {minWidth: 640, maxWidth: 767, itemsToShow: 2},
              {maxWidth: 639, itemsToShow: 1},
            ]}
            easing="cubic-bezier(0.68, -0.6, 0.32, 1.6)"
            itemsToShow={4}
            speed={600}
            itemsToScroll={1}
          >
            {homeContent.affiliations.map(({ logoClassName }, index) => (
              <div key={`logo-${index}`} className="p-10"><AffiliateLogo className={logoClassName} /></div>
            ))}
          </Carousel>
        </div>
      </section>
      <section id="section-offer">
        <div className="flex flex-col items-center justify-center mx-auto py-16 md:py-32 px-5 md:px-10">
          <h2 className="text-brand-gray-600 text-4xl font-bold uppercase">What we Offer</h2>
          <span className="text-brand-gray text-xl md:text-2xl my-6 text-center max-w-6xl" >{homeContent.offer.tagLine}</span>
          <div className="flex flex-col md:flex-row justify-between items-start max-w-screen-3xl w-full py-5 md:py-20">
            {homeContent.offer.services.map(({ icon, title, description}) => {
              return (
                <div className="w-full md:w-72 flex flex-col justify-center items-center mb-10 md:mb-0">
                  <div className="flex flex-col items-center">
                    {icon}
                    <h4 className="text-center text-brand-gray text-xl xl:text-2xl font-bold uppercase md:my-10 md:h-16 my-2 md:my-0">{title}</h4>
                  </div>
                  <span className="text-center text-lg xl:text-xl text-brand-gray-600 px-2">{description}</span>
                </div>
              )
            })}
          </div>
          <Link to="/services"
            className="mt-10 hover:bg-brand-yellow group flex items-center rounded-full bg-brand-yellow text-brand-gray text-xl font-bold px-10 md:px-24 py-4">
            More About Our Services
          </Link>

        </div>
      </section>
      <section id="section-know" className="flex flex-col md:flex-row w-full pt-20 pb-0 md:pb-48">
        <div className="relative w-full md:w-6/12 xl:w-7/12">
          <div style={{backgroundPosition: '50% 30%' }}
            className="bg-meet-us bg-cover flex flex-col items-center justify-center py-28 md:py-56"/>
          <div className="hidden md:block absolute w-28 -bottom-px -top-px -right-px overflow-hidden">
            <div
              className="w-full h-full absolute pointer-events-none overflow-hidden"
            >
              <svg
                className="h-full w-full"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 100 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-white fill-current"
                  points="0,0, 95,50 0,100 100,100 100,0"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center md:items-start justify-center w-full md:w-6/12 xl:w-5/12 px-5 md:px-20 py-10 md:py-0">
          <h2 className="text-brand-gray-600 text-4xl font-bold uppercase">Get To Know Us</h2>
          <span className="text-brand-gray text-center md:text-left text-2xl md:text-base lg:text-xl 2xl:text-2xl my-6 max-w-2xl" >{homeContent.know}</span>
          <Link to="/about"
            className="xl:mt-10 hover:bg-brand-yellow group flex items-center rounded-full bg-brand-yellow text-brand-gray text-xl font-bold px-8 py-4">
            Learn More
          </Link>
        </div>
      </section>
      <ContactUsAction />
      <section id="section-testimonials">
        <div className="flex flex-col items-center justify-center w-full mx-auto mb-44 mt-48 px-5 md:px-16">
          <Carousel
            updateOnItemClick
            containerProps={{className: "w-full justify-between"}}
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: <HiOutlineArrowNarrowRight className="text-4xl sm:text-5xl text-brand-yellow focus:outline-none mb-16" />,
              className: "focus:outline-none self-center",
            }}
            backwardBtnProps={{
              children: <HiOutlineArrowNarrowLeft className="text-4xl sm:text-5xl text-brand-yellow focus:outline-none mb-16" />,
              className: "focus:outline-none self-center",
            }}
            centerMode={true}
            responsiveProps={[
              {}
            ]}
            easing="cubic-bezier(0.68, -0.6, 0.32, 1.6)"
            itemsToShow={1}
            speed={1500}
            itemsToScroll={1}
          >
            {homeContent.testimonials.map(({ body, customer }, index) => (
              <div key={`testimonial-${index}`} className="w-7xl px-32 text-center text-brand-gray">
                <div className="w-56 sm:w-md lg:w-2xl xl:w-4xl mx-auto">
                  {/* w-full max-w-xs lg:max-w-2xl 2xl:max-w-5xl */}
                  <ImQuotesLeft className="text-3xl md:text-5xl text-brand-yellow"/>
                  <div className="text-2xl lg:text-4xl leading-tight">{body}</div>
                  <div className="text-2xl mt-8">{customer}</div>
                  <ImQuotesRight className="ml-auto text-3xl md:text-5xl text-brand-yellow -mt-8 md:-mt-12"/>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </>
  )
}
