import Header from "../components/Header";
import ContactUsAction from "../components/ContactUsAction";
import {FaLinkedin} from "react-icons/fa";

const AboutContent = {
  story: [
    <p><i>Sunshine Environmental Solutions</i> is an industrial hygiene company and environmental pollution consultancy serving the private and public sectors. Committed to community wellness and workplace health, we specialize in lead, mold, air, soil, and water testing, toxic lead and mold remediation, asbestos survey, asbestos project design, asbestos project monitoring, and more. Our clients are building contractors, property managers, real estate companies, architects, and individuals.</p>,
    <p className="font-bold italic text-center">Committed to community wellness and workplace health.</p>,
    <p>The origin of our company begins with necessity. When Olusegun “Olu” Osanyingbemi purchased his Long Island home, he discovered asbestos in his basement. As a professional student with a background in chemistry, Olu found himself fascinated with the potential project. Sensing a need, Olu started his career as an industrial hygienist and environmental consultant in 1998. He worked with Taylor Environmental, and other environmental companies in a span of 20 years before   founding Sunshine Environmental Solutions in 2016. The company has continued to develop thanks to Olu’s thirst for knowledge and tireless work ethic.</p>,
    <p className="font-bold italic text-center">Trust, Ingenuity, Hard Work.</p>,
    <p>Sunshine gets its name from Olu, who is famous amongst family, friends, and clients for his sunny disposition. A product  of Nigeria, Olu embodies the persistence, hard work, and ingenuity that his hometown is known for. His work ethic is second only to his sense of humor. At Sunshine, we enjoy our work, and always take our duty to protect your community seriously.</p>,
    <p className="font-bold italic text-center">When you work with Sunshine, you’re working with family.</p>,
    <p>We care about the wellness of your community because we know the value of community personally. We are a family business with Olu’s wife, son, and daughters, each bringing unique skillsets to the team. Together we believe in trust, friendship, and resilience, hard work, relationships, and giving back. We create genuine connections with our clients that last a lifetime. When you work with Sunshine, you’re working with family.</p>,
    <p className="font-bold italic  text-center">Our motto: "We can do that."</p>,
    <p>Thank you for considering Sunshine Environmental Solutions for your project. Working with us, you receive not just our expertise, but our partnership. We work hard to identify the source of the problem and go further to anticipate your needs. With deep roots in the environmental solutions sphere and an extensive network of top-notch connections, we help our clients tap the resources necessary to move their projects from beginning to end.</p>
  ],
  founders: [
    {
      imgClassName: 'bg-dad',
      name: 'Olu Osan',
      title: 'Chief Executive Officer',
      bio: '',
      linkedIn: 'https://www.linkedin.com/in/olusegun-osanyingbemi-a7703719/'
    },
    {
      imgClassName: 'bg-mom',
      name: 'Joyce Osan',
      title: 'Chief Financial Officer',
      bio: '',
      linkedIn: 'https://www.linkedin.com/in/joyce-osan-509aa14a'
    }
  ]
}

export default function About(){
  return (
    <>
      <Header title="WHO WE ARE" className="bg-sunflowers bg-cover bg-center">
        <div className="w-full h-full absolute top-0 left-0 bg-brand-gray opacity-50" />
      </Header>
      <section className="max-w-screen-lg mx-auto">
        <div className="flex flex-col items-center justify-center pt-40 pb-20 max-w-6xl mx-auto">
          <hr className="w-32 border-brand-yellow mb-5 border-t-3" />
          <h2 className="text-brand-gray-600 text-4xl font-bold uppercase">Our Story</h2>
          <hr className="w-32 border-brand-yellow mt-5 border-t-3" />
        </div>

        {AboutContent.story.map((body) => (<div className="text-2xl mb-10 text-brand-gray-600">{body}</div>))}
      </section>
      <section className="max-w-screen-2xl mx-auto py-40 ">
        <div className="flex flex-col items-center justify-center pb-20 max-w-6xl mx-auto">
          <hr className="w-32 border-brand-yellow mb-5 border-t-3" />
          <h2 className="text-brand-gray-600 text-4xl font-bold uppercase">Our Founders</h2>
          <hr className="w-32 border-brand-yellow mt-5 border-t-3" />
        </div>
        <div className="flex justify-around">
          {AboutContent.founders.map(({ name, title, bio, linkedIn, imgClassName}) => (
            <div className="flex flex-col items-center text-brand-gray-600 w-4/12">
              <div className={`bg-brand-gray-300 rounded-full mb-14 ${imgClassName} bg-cover bg-center w-40 h-40 sm:w-72 sm:h-72 md:w-96 md:h-96 xl:w-125 xl:h-125`} />
              <h3 className="text-brand-gray-600 text-3xl font-bold uppercase text-center flex items-center justify-center">
                {name}
                <a href={linkedIn} target="_blank" rel="noopener noreferrer" className="flex justify-center ml-2">
                  <FaLinkedin className="text-2xl text-brand-yellow inline"/>
                </a>
              </h3>
              <span className="text-xl italic mb-7 text-center">{title}</span>
              {/*<p className="mb-7 text-center">{bio}</p>*/}
            </div>
          ))}
        </div>
      </section>
      <ContactUsAction />
    </>

  )
}
