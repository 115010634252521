import {
  Link
} from "react-router-dom"

import logo from './../assets/img/logo.png'
import {Disclosure} from "@headlessui/react";
import { MenuIcon, XIcon} from "@heroicons/react/outline";

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Services', href: '/services' },
  { name: 'About', href: '/about' },
  { name: 'Contact', href: '/contact' },
]

export default function Navbar() {
  return (
    <>
      <Disclosure as="nav" className="bg-white">
        {({ open }) => (
          <>
            <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 py-3 sm:py-6 lg:py-11">
              <div className="flex w-full">
                <div className="flex w-full justify-between">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block h-10 sm:h-16 lg:h-24 w-auto"
                      src={logo}
                      alt=""
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center">
                    {navigation.map((link) => (
                      <Link key={link.name} to={link.href} className="text-lg uppercase font-semibold text-brand-gray-dark border-b-2 border-brand-yellow pb-1 border-opacity-0 hover:border-opacity-100 focus:border-opacity-100">
                        {link.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-yellow-300">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 space-y-1">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                {navigation.map((link) => (
                  <Disclosure.Button
                    as={Link} key={link.name} to={link.href}
                    className="block text-lg uppercase font-semibold text-brand-gray-dark border-l-4 border-transparent pl-3 pr-4 py-2 hover:border-brand-yellow hover:bg-brand-yellow-100 hover:text-brand-yellow-700 focus:border-opacity-100"
                  >
                    {link.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
