import { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom"
import './App.css';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar transparent/>
      <Switch>
        <Route path="/about"><About /></Route>
        <Route path="/services"><Services /></Route>
        <Route path="/contact"><Contact /></Route>
        <Route path="/"><Home /></Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
